<template>
  <div class="flex flex-col items-center justify-center py-10">
    <div>
      <img
        src="../../../../assets/img/empty-states/admin-teams.svg"
        width="415"
      />
    </div>
    <div class="mt-2">
      <h2 class="font-barlow text-mdxl font-bold text-grey-dark-2">
        No Teams Created Yet
      </h2>
    </div>
    <div class="mt-2 w-2/6 text-center">
      <p class="font-barlow text-mdh text-grey-light">
        Create teams to be monitored by managers in your company. You can also
        use teams to target specific participants within an assessment.
      </p>
    </div>
    <div>
      <div class="my-2">
        <Button
          text="+ New Team"
          type="primary"
          size="medium"
          @click.native="createTeam"
          data-testid="create-team-button"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminTeamsEmpty",
  props: {
    createTeam: { type: Function, default: () => null },
  },
};
</script>

<style></style>
