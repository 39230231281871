<template>
  <div>
    <div class="flex flex-row w-full py-1 pl-2 items-center">
      <div class="flex flex-grow w-1/20">
        <p class="font-normal text-md text-grey-dark-2">
          {{ index + 1 }}
        </p>
      </div>
      <div class="w-1/3 pr-2 xl:w-1/2">
        <p class="text-md text-grey-dark-1 font-semibold">
          {{ source.name }}
        </p>
      </div>
      <div class="flex flex-row w-1/4 xl:w-1/3">
        <p
          class="text-md"
          :class="!this.source.manager ? 'text-red' : 'text-grey-dark-2'"
        >
          {{ managerName }}
        </p>
      </div>
      <div class="flex justify-center w-1/4 xl:w-1/6">
        <p class="text-md text-grey-dark-2">
          {{ source.users.length || "0" }}
        </p>
      </div>
      <div class="flex flex-row w-1/5">
        <p
          class="text-md"
          :class="[
            {
              'text-red': !source.isActive,
            },
            {
              'text-green-2': source.isActive,
            },
          ]"
        >
          {{ isActive }}
        </p>
      </div>
      <div class="flex flex-row justify-center focus:outline-none w-1/5">
        <Button
          text="Manage"
          type="secondary"
          size="medium"
          @click.native="manage(source.id)"
        />
      </div>
    </div>
    <div class="border border-grey-light-1 border-top-none" />
  </div>
</template>
<script>
export default {
  name: "ListItem",
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
    manage: { type: Function, default: () => null },
  },
  computed: {
    managerName() {
      return this.source.manager
        ? `${this.source.manager.firstName} ${this.source.manager.lastName}`
        : "No manager assigned";
    },
    isActive() {
      return this.source.isActive ? "Active" : "Deactivated";
    },
  },
};
</script>
