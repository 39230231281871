<template>
  <div>
    <h1
      class="text-xl text-grey-light font-medium font-headers mb-3 font-bold text-grey-dark-3"
    >
      Teams
    </h1>
    <div class="bg-white shadow p-3 rounded">
      <TeamTable />
    </div>
  </div>
</template>

<script>
import TeamTable from "../components/TeamTable/index";
import { mapActions } from "vuex";
export default {
  name: "AdminTeams",
  components: { TeamTable },
  methods: {
    ...mapActions({
      getManagers: "adminTeams/getManagers",
    }),
  },
  mounted() {
    this.getManagers();
  },
};
</script>

<style>
</style>